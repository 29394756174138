import { retrieveTokens } from "amazon-cognito-passwordless-auth/storage";
import type { ReteynSchema } from "../schema";
import { collect, parseTruthyResponse, toIterable } from "../dao";
import { jwtDecode } from "jwt-decode";
import { V6Client } from "@aws-amplify/api-graphql";

export async function createQuizAndSendToCurrentUser(
  client: V6Client<ReteynSchema>,
  request: {
    organisationId: string;
    questionId: string;
    studentDashboardOrigin: string;
  },
): Promise<ReteynSchema["Test"]["type"]> {
  const tokens = await retrieveTokens();
  const { email } = jwtDecode<{ email: string }>(tokens!.idToken as string);
  const aliases = toIterable((nextToken) =>
    client.models.Contact.listContactByEmailAndOrganisationId(
      { email, organisationId: { eq: request.organisationId } },
      { nextToken, selectionSet: ["id", "owner"] },
    ),
  );
  const [existingContact] = await collect(aliases);
  const contact =
    existingContact ||
    (await parseTruthyResponse(
      client.models.Contact.create({
        email,
        organisationId: request.organisationId,
      }),
    ));
  const test = await parseTruthyResponse(
    client.models.Test.create({
      questionId: request.questionId,
      contactId: contact.id,
      owner: contact.owner as string,
    }),
  );
  return test as ReteynSchema["Test"]["type"];
}
